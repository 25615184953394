<template>
    <v-list two-line>
        <v-text-field v-if="canSearch" v-model="searchTerm" label="Zoek werkbonnen"></v-text-field>
        <v-skeleton-loader
            v-if="$store.state.workOrder.loading"
            type="article, article"
        />
        <template v-for="(workOrder, index) in workOrdersByDay" v-else>
            <h4
                :key="index"
                v-if="workOrder.newDay"
            >
                {{ workOrder.newDay|luxon({output: { format: 'date_huge' } }) }}
            </h4>

            <v-list-item
                :key="index"
                :to="{name:'WorkOrderView', params: { id: workOrder.id } }"
                v-else
            >
                <v-list-item-action>
                    <v-list-item-action-text>{{ workOrder.voucherNumber }}</v-list-item-action-text>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>{{ workOrder.project.customerName }}</v-list-item-title>
                    <v-list-item-subtitle>
                        {{ workOrder.description }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
export default {
    name: "WorkOrderList",
    props: {
        isDashboard: {type: Boolean, default: false, required: false},
        canSearch: {type: Boolean, default: false, required: false}
    },
    data: () => ({
        searchTerm: '',
    }),
    computed: {
        workOrders() {
            if (this.isDashboard) {
                return this.$store.getters.getDashboardWorkOrders
            } else {
                return this.$store.state.workOrder.workOrders
            }
        },
        workOrdersByDay() {
            let result = [];
            let lastDay = null;
            // put dividers between for each next day
            this.workOrders.forEach(item => {
                let day = this.$luxon(item.appointmentDateAt, 'o');
                if (day !== lastDay) result.push({newDay: item.appointmentDateAt});
                result.push(item);
                lastDay = day;
            });
            return result;
        }
    },
    methods: {
        loadWorkOrders() {
            this.$store.dispatch('getWorkOrders');
        },
        searchWorkOrders() {
            this.$store.dispatch('searchWorkOrders', { searchTerm: this.searchTerm })
        },
    },
    mounted() {
        this.loadWorkOrders();
    },
    watch: {
        searchTerm() {
            if (this.searchTerm !== '') {
                this.searchWorkOrders()
            } else {
                this.loadWorkOrders()
            }
        },
    }
}
</script>

<style scoped>

</style>
